<template lang="html">
	<div class="wrappers fadeInDown">
		<div id="formContent" v-show="formShow">
			<!-- Tabs Titles -->

			<!-- Icon -->
			<div class="fadeIn first">
				<img src="../../assets/img/logo.png" id="icon" alt="User Icon" />
			</div>

			<!-- Login Form -->
			<form @submit.prevent="smsVerify">
				<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
				<small class="text-danger">{{message}}</small>
				<small class="text-success">{{successmessage}}</small>
				<ul class="list-group" style="margin: 0px 35px 10px 35px;" v-if="validate == true">
					<li v-if="validationData.lat" class="list-group-item list-group-item-danger"><small class="text-danger">{{validationData.lat}}</small></li>
					<li v-if="validationData.long"  class="list-group-item list-group-item-danger"><small class="text-danger">{{validationData.long}}</small></li>
					<li v-if="validationData.name"  class="list-group-item list-group-item-danger"><small class="text-danger">{{validationData.name}}</small></li>
					<li v-if="validationData.phone"  class="list-group-item list-group-item-danger"><small class="text-danger">{{validationData.phone}}</small></li>
					<li v-if="validationData.password"  class="list-group-item list-group-item-danger"><small class="text-danger">{{validationData.password}}</small></li>
					<li v-if="validationData.confirmPassword"  class="list-group-item list-group-item-danger"><small class="text-danger">{{validationData.confirmPassword}}</small></li>
				</ul>
				<a href="#" v-bind:class="{'btn btn-primary': location == false, 'btn btn-success' : location == true}"
					@click="locatorButtonPressed()" style="width: 85%;color: #ffffff;">{{locationMessage}}</a>
				<input type="text" v-model="regRequest.name" class="fadeIn second" name="name" placeholder="အမည်">
				<input type="text" v-model="regRequest.phone" class="fadeIn second" name="phone"
					placeholder="ဖုန်းနံပါတ်">
				<input type="password" v-model="regRequest.password" class="fadeIn second" name="password"
					placeholder="စကားဝှက်">
				<input type="password" v-model="regRequest.confirmPassword" class="fadeIn second" name="confirm"
					placeholder="စကားဝှက်ကို ပြန်လည် သက်မှတ်မည်">
				<input type="submit" class="fadeIn fourth" value="စာရင်းသွင်းမည်">
			</form>

			<!-- Remind Passowrd -->
			<div id="formFooter">
				အကောင့်ရှိပြီးသားလား <router-link to="/" class="underlineHover">ဝင်ရောက်ပါ </router-link>
			</div>
		</div>
		<div id="formContent" v-show="otpShow">
			<!-- Tabs Titles -->

			<!-- Icon -->
			<div class="fadeIn first">
				<img src="../../assets/img/logo.png" id="icon" alt="User Icon" />
				<h6 class="modal-title" id="verifyModalCenterTitle">သင့်ဖုန်းနံပါတ်သို့ OTP Code ပို့လိုက်ပါပြီ</h6>
			</div>

			<!-- Login Form -->
			<form @submit.prevent="register">
				<small class="text-danger">{{otpMessage}}</small>
				<input type="text" v-model="regRequest.otp" class="fadeIn second" name="otp" placeholder="OTP">
				<input type="submit" class="fadeIn fourth" value="မှတ်ပုံတင်မည်">
			</form>

		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapActions
	} from "vuex"

	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';

	export default {
		name: 'register',
		components: {
			Loading
		},
		data() {
			return {
				formShow: true,
				otpShow: false,
				isLoading: false,
				fullPage: true,
				message: "",
				successmessage: "",
				otpMessage: "",
				validate: false,
				locationMessage: "တည်နေရာ ရယူမည်",
				location: false,
				regRequest: {
					name: '',
					phone: '',
					lat: '',
					long: '',
					password: '',
					otp: ''
				},
				validationData: {
					name: "",
					phone: "",
					lat: "",
					long: '',
					password: "",
					confirmPassword: ""
				}
			}
		},
		computed: {
			...mapState({
				user: state => state.user,
			}),
		},
		methods: {
			...mapActions({
				smsVeirfyAction: 'smsVeirfyAction',
				registerAction: 'registerAction',
			}),
			locatorButtonPressed() {
				navigator.geolocation.getCurrentPosition(
					position => {
						this.regRequest.lat = position.coords.latitude
						this.regRequest.long = position.coords.longitude
						this.location = true
						this.locationMessage = "တည်နေရာ ရယူပြီးပါပြီ"
					},
					error => {
						console.log(error.message);
					},
				)
			},
			async smsVerify() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = {
					phone: this.regRequest.phone,
				}
				this.smsVeirfyAction({
					...option
				}).then(res => {
					if (res.status === 'success') {
						this.otpShow = true
						this.formShow = false
						this.isLoading = false
					} else {
						this.message = res.message
						this.isLoading = false
					}
				}).catch(err => this.loading = true)
			},
			async register() {
				this.registerAction({
					...this.regRequest
				}).then(res => {
					if (res.status === 'success') {
						this.$router.replace('/')
						this.successmessage = "Register process is success."
					} else {
						this.otpMessage = res.message
					}
				}).catch(err => this.loading = true)
			},
			validationRule() {
				let isValidation = []
				if (this.regRequest.lat == "") {
					this.validationData.lat = "တည်နေရာ Latitude ဖြည့်ပါ"
					isValidation.push(true)
				} else {
					this.validationData.lat = ""
					isValidation.push(false)
				}
				if (this.regRequest.long == "") {
					this.validationData.long = "တည်နေရာ longitude ဖြည့်ပါ"
					isValidation.push(true)
				} else {
					this.validationData.long = ""
					isValidation.push(false)
				}
				if (this.regRequest.name == "") {
					this.validationData.name = "အမည် ဖြည့်ပါ"
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.regRequest.phone == "") {
					this.validationData.phone = "ဖုန်းနံပါတ် ဖြည့်ပါ"
					isValidation.push(true)
				} else {
					this.validationData.phone = ""
					isValidation.push(false)
				}

				if (this.regRequest.password == "") {
					this.validationData.password = "စကားဝှက်  ဖြည့်ပါ"
					isValidation.push(true)
				} else {
					this.validationData.password = ""
					isValidation.push(false)
				}
				if (this.regRequest.confirmPassword == "") {
					this.validationData.confirmPassword = "စကားဝှက် အသစ် ဖြည့်ပါ"
					isValidation.push(true)
				} else {
					this.validationData.confirmPassword = ""
					isValidation.push(false)
				}
				if (this.regRequest.password != this.regRequest.confirmPassword) {
					this.validationData.confirmPassword = "စကားဝှက် မကိုက်ညီပါ"
					isValidation.push(true)
				} else {
					this.validationData.confirmPassword = ""
					isValidation.push(false)
				}

				if (isValidation.includes(true)) {
					this.validate = true
					return true
				} else {
					this.validate = false
					return false
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	@import "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css";
	@import "https://use.fontawesome.com/releases/v5.8.2/css/all.css";
	/* BASIC */

	html {
		background-color: #56baed;
	}

	body {
		font-family: "Poppins", sans-serif;
		height: 100vh;
	}

	a {
		color: #92badd;
		display: inline-block;
		text-decoration: none;
		font-weight: 400;
	}

	h2 {
		text-align: center;
		font-size: 16px;
		font-weight: 600;
		text-transform: uppercase;
		display: inline-block;
		margin: 40px 8px 10px 8px;
		color: #cccccc;
	}



	/* STRUCTURE */

	.wrappers {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding: 20px;
	}

	#formContent {
		-webkit-border-radius: 10px 10px 10px 10px;
		margin-top: 10%;
		border-radius: 10px 10px 10px 10px;
		background: #fff;
		padding: 30px;
		width: 90%;
		max-width: 450px;
		position: relative;
		padding: 0px;
		-webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
		box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
		text-align: center;
	}

	#formFooter {
		background-color: #f6f6f6;
		border-top: 1px solid #dce8f1;
		padding: 25px;
		text-align: center;
		-webkit-border-radius: 0 0 10px 10px;
		border-radius: 0 0 10px 10px;
	}



	/* TABS */

	h2.inactive {
		color: #cccccc;
	}

	h2.active {
		color: #0d0d0d;
		border-bottom: 2px solid #5fbae9;
	}



	/* FORM TYPOGRAPHY*/

	input[type=button],
	input[type=submit],
	input[type=reset] {
		background-color: #56baed;
		border: none;
		color: white;
		padding: 15px 80px;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		text-transform: uppercase;
		font-size: 13px;
		-webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
		box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
		-webkit-border-radius: 5px 5px 5px 5px;
		border-radius: 5px 5px 5px 5px;
		margin: 5px 20px 40px 20px;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	input[type=button]:hover,
	input[type=submit]:hover,
	input[type=reset]:hover {
		background-color: #39ace7;
	}

	input[type=button]:active,
	input[type=submit]:active,
	input[type=reset]:active {
		-moz-transform: scale(0.95);
		-webkit-transform: scale(0.95);
		-o-transform: scale(0.95);
		-ms-transform: scale(0.95);
		transform: scale(0.95);
	}

	input[type=text] {
		background-color: #f6f6f6;
		border: none;
		color: #0d0d0d;
		padding: 15px 32px;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		font-size: 16px;
		margin: 5px;
		width: 85%;
		border: 2px solid #f6f6f6;
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		-webkit-border-radius: 5px 5px 5px 5px;
		border-radius: 5px 5px 5px 5px;
	}

	input[type=text]:focus {
		background-color: #fff;
		border-bottom: 2px solid #5fbae9;
	}

	input[type=text]:placeholder {
		color: #cccccc;
	}

	input[type=password] {
		background-color: #f6f6f6;
		border: none;
		color: #0d0d0d;
		padding: 15px 32px;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		font-size: 16px;
		margin: 5px;
		width: 85%;
		border: 2px solid #f6f6f6;
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		-webkit-border-radius: 5px 5px 5px 5px;
		border-radius: 5px 5px 5px 5px;
	}

	input[type=tpasswordxt]:focus {
		background-color: #fff;
		border-bottom: 2px solid #5fbae9;
	}

	input[type=password]:placeholder {
		color: #cccccc;
	}



	/* ANIMATIONS */

	/* Simple CSS3 Fade-in-down Animation */
	.fadeInDown {
		-webkit-animation-name: fadeInDown;
		animation-name: fadeInDown;
		-webkit-animation-duration: 1s;
		animation-duration: 1s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	}

	@-webkit-keyframes fadeInDown {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(0, -100%, 0);
			transform: translate3d(0, -100%, 0);
		}

		100% {
			opacity: 1;
			-webkit-transform: none;
			transform: none;
		}
	}

	@keyframes fadeInDown {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(0, -100%, 0);
			transform: translate3d(0, -100%, 0);
		}

		100% {
			opacity: 1;
			-webkit-transform: none;
			transform: none;
		}
	}

	/* Simple CSS3 Fade-in Animation */
	@-webkit-keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	@-moz-keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	.fadeIn {
		opacity: 0;
		-webkit-animation: fadeIn ease-in 1;
		-moz-animation: fadeIn ease-in 1;
		animation: fadeIn ease-in 1;

		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		animation-fill-mode: forwards;

		-webkit-animation-duration: 1s;
		-moz-animation-duration: 1s;
		animation-duration: 1s;
	}

	.fadeIn.first {
		-webkit-animation-delay: 0.4s;
		-moz-animation-delay: 0.4s;
		animation-delay: 0.4s;
	}

	.fadeIn.second {
		-webkit-animation-delay: 0.6s;
		-moz-animation-delay: 0.6s;
		animation-delay: 0.6s;
	}

	.fadeIn.third {
		-webkit-animation-delay: 0.8s;
		-moz-animation-delay: 0.8s;
		animation-delay: 0.8s;
	}

	.fadeIn.fourth {
		-webkit-animation-delay: 1s;
		-moz-animation-delay: 1s;
		animation-delay: 1s;
	}

	/* Simple CSS3 Fade-in Animation */
	.underlineHover:after {
		display: block;
		left: 0;
		bottom: -10px;
		width: 0;
		height: 2px;
		background-color: #56baed;
		content: "";
		transition: width 0.2s;
	}

	.underlineHover:hover {
		color: #0d0d0d;
	}

	.underlineHover:hover:after {
		width: 100%;
	}



	/* OTHERS */

	*:focus {
		outline: none;
	}

	#icon {
		width: 60%;
	}

	.modal-content {
		margin: 0px !important;
		height: 97vh !important;
	}
</style>